import React from 'react';
import { Helmet } from 'react-helmet';
import withLocation, { WithLocationProps } from '../../hoc/withLocation';
import useCanonicalUrl from '../../hooks/useCanonicalUrl';
import useSiteMetaData from '../../hooks/useSiteMetaData';

interface Props extends WithLocationProps {
    title?: string;
}

const Seo = ({ location, title }: Props): JSX.Element => {
    const siteMetaData = useSiteMetaData();
    const canonicalUrl = useCanonicalUrl({ location: location! }); // We know this is not ever going to be not null

    const seo = {
        title: title ? `${title} | ${siteMetaData.title}` : siteMetaData.title,
        description: siteMetaData.description,
        image: siteMetaData.image,
        url: siteMetaData.url
    };

    return (
        <Helmet>
            {seo.title && <title>{seo.title}</title>}
            <meta name="description" content={seo.description} />
            <link rel="canonical" href={canonicalUrl} />
            <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            <meta name="thumbnail" content={seo.image} />
            <meta name="image" content={seo.image} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
        </Helmet>
    );
};

export default withLocation(Seo);
