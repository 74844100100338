/* eslint-disable func-names */
import React from 'react';
import { Location, LocationContext, } from '@reach/router';

export type WithLocationProps = Partial<LocationContext>;

const withLocation = <T, >(ComponentToWrap: React.ComponentType<T>) => function (props: T): JSX.Element {
    return (
        <Location>
            {
                ({ location, navigate }) => (
                    <ComponentToWrap
                        {...props}
                        location={location}
                        navigate={navigate}
                    />
                )
            }
        </Location>
    );
};

export default withLocation;
