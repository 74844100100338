import useSiteMetaData from './useSiteMetaData';


type CanonicalURL = string;

interface Options {
    canonicalUrl?: string,
    location: {pathname: string}
}

/**
 * Hook to safely grab canonical url sitewide.
 * @param {string} canonicalUrl
 * @param {object} location
 * @returns {CanonicalURL}
 */
const useCanonicalUrl = ({ canonicalUrl, location }: Options): CanonicalURL => {
    const { url } = useSiteMetaData();

    let currentUrl = `${url}`;
    if (currentUrl.endsWith('/')) currentUrl = currentUrl.substring(0, currentUrl.length - 1);
    currentUrl += location.pathname;
    if (currentUrl.endsWith('/')) currentUrl = currentUrl.substring(0, currentUrl.length - 1);

    return (canonicalUrl && canonicalUrl !== '') ? canonicalUrl : currentUrl;
};

export default useCanonicalUrl;
