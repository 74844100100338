import { useStaticQuery, graphql } from 'gatsby';


export interface SiteMetaData {
    url: string;
    title: string;
    description: string;
    image: string;
}
/**
 * Grabs the site meta from the gatsby config and returns it so that we can use it sitewide.
 * @returns
 */
const useSiteMetaData = (): SiteMetaData => {
    const {
        site: { siteMetadata }
    } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    url
                    title
                    description
                    image
                    url
                }
            }
        }
    `);

    return {
        ...siteMetadata,
    };
};

export default useSiteMetaData;
